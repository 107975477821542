import BasePage from '../../../../../../components/table_page';

import Register, { ProcessForm } from '../../components';
import request from '../../../../../../utils/request';

export default {
  name: 'activity_overview',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form: Register,
    ProcessForm,
  },
  created() {
    this.getConfigList('tpm_activity_overview_list');
  },
  methods: {
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 待提交 create
      // 审批中 approving
      // 审批通过 approved
      // 驳回 rejected
      // 流程追回 interrupt
      // 活动关闭 closed
      const { approveStatus, processCode } = row;
      if (
        code === 'edit'
        && approveStatus !== 'create'
        && approveStatus !== 'rejected'
        && approveStatus !== 'interrupt'
      ) {
        return false;
      }
      if (
        code === 'submit_approval'
        && approveStatus !== 'create'
        && approveStatus !== 'rejected'
      ) {
        return false;
      }

      if (code === 'process_log' && !processCode) {
        return false;
      }

      return true;
    },
    // 删除前置
    beforeButtonClick({ val }) {
      let list = []; let
        state = true;
      if (val.code === 'delete') {
        if (this.selectRow.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }

        list = this.selectRow.map((v) => v.approveStatus);

        state = list.every((v) => v === 'create');
        if (!state) {
          this.$message.error('只有待提交的数据允许删除操作');
          return false;
        }
      }
      return true;
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      } else if (code === 'submit_approval') {
        request
          .get('/tpm/tpmLiqueurActRegisterController/query', {
            id: row.id,
          })
          .then((res) => {
            if (res.success) {
              const rowData = { ...res.result };
              rowData.saveType = '5';
              this.onSubmit({
                row: rowData,
                submitUrl: '/tpm/tpmLiqueurActRegisterController/approve',
              });
            }
          });
      } else if (code === 'process_log') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row: { processNo: row.processCode },
        };

        this.modalConfig.title = '流程日志';

        this.formName = 'ProcessForm';
        this.openDrawer();
      }
    },
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
